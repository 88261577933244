import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {BreadcrumbItem, Role} from "../../types";
import client from "../../services/client";
import {AxiosResponse} from "axios";
import {
  Box,
  List,
  ListItem,
  ListItemText, Paper,
  styled,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import * as Yup from 'yup';
import {Formik, FormikHelpers} from "formik";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import {Add, Delete, EditOutlined, People, Person, VisibilityOutlined} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Breadcrumb from "../App/Breadcrumb";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: '1px solid rgba(41, 55, 68, 0.1)',
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
      border: '1px solid rgba(41, 55, 68, 0.1)',
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(0, 122, 252, 0.1)',
      color: 'rgba(0, 122, 252)',
    },
  },
}));

const Form = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [role, setRole] = React.useState<Role>({id: 0, name: '', roles: []});
  const [selectedRoles, setSelectedRoles] = React.useState<string[]>(role.roles);

  useEffect(() => {
    if (params.id === undefined) {
      return;
    }

    client
      .get(`/role/${params.id}`)
      .then((response: AxiosResponse) => {
        setRole(response.data);
        setSelectedRoles(response.data.roles);
      })
    ;
  }, [params.id]);

  const RoleHierarchy = [
    {
      name: 'Algemeen',
      roleCollections: [
        {
          name: 'Gebruikers',
          roles: [
            {type: 'get', value: 'ROLE_USER_GET'},
            {type: 'create', value: 'ROLE_USER_CREATE'},
            {type: 'update', value: 'ROLE_USER_UPDATE'},
            {type: 'delete', value: 'ROLE_USER_DELETE'},
          ],
        },
        {
          name: 'Rollen',
          roles: [
            {type: 'get', value: 'ROLE_ROLE_GET'},
            {type: 'create', value: 'ROLE_ROLE_CREATE'},
            {type: 'update', value: 'ROLE_ROLE_UPDATE'},
            {type: 'delete', value: 'ROLE_ROLE_DELETE'},
          ],
        },
        {
          name: 'Klanten',
          roles: [
            {type: 'get', value: 'ROLE_CUSTOMER_GET'},
          ],
        },
        {
          name: 'Facturatie',
          roles: [
            {type: 'get', value: 'ROLE_INVOICE_GET'},
          ],
        },
      ],
    },
    {
      name: 'Projecten',
      roleCollections: [
        {
          name: 'Beheer',
          roles: [
            {type: 'get', value: 'ROLE_PROJECT_GET'},
            {type: 'create', value: 'ROLE_PROJECT_CREATE'},
            {type: 'update', value: 'ROLE_PROJECT_UPDATE'},
            {type: 'delete', value: 'ROLE_PROJECT_DELETE'},
          ],
        },
        {
          name: 'Links',
          roles: [
            {type: 'get', value: 'ROLE_PROJECT_LINK_GET'},
            {type: 'create', value: 'ROLE_PROJECT_LINK_CREATE'},
            {type: 'update', value: 'ROLE_PROJECT_LINK_UPDATE'},
            {type: 'delete', value: 'ROLE_PROJECT_LINK_DELETE'},
          ],
        }
      ]
    },
    {
      name: 'DNS',
      roleCollections: [
        {
          name: 'Beheer',
          roles: [
            {type: 'dns_own', value: 'ROLE_DNS'},
            {type: 'dns_all', value: 'ROLE_DNS_ALL'},
          ]
        }
      ],
    },
    {
      name: 'Kubernetes',
      roleCollections: [
        {
          name: 'Clusters',
          roles: [
            {type: 'get', value: 'ROLE_KUBERNETES_CLUSTER_GET'},
            {type: 'create', value: 'ROLE_KUBERNETES_CLUSTER_CREATE'},
            {type: 'update', value: 'ROLE_KUBERNETES_CLUSTER_UPDATE'},
            {type: 'delete', value: 'ROLE_KUBERNETES_CLUSTER_DELETE'},
          ],
        },
        {
          name: 'Namespaces',
          roles: [
            {type: 'get', value: 'ROLE_KUBERNETES_NAMESPACE_GET'},
            {type: 'create', value: 'ROLE_KUBERNETES_NAMESPACE_CREATE'},
            {type: 'update', value: 'ROLE_KUBERNETES_NAMESPACE_UPDATE'},
            {type: 'delete', value: 'ROLE_KUBERNETES_NAMESPACE_DELETE'},
          ],
        },
      ],
    },
    {
      name: 'Database',
      roleCollections: [
        {
          name: 'Servers',
          roles: [
            {type: 'get', value: 'ROLE_DATABASE_SERVER_GET'},
            {type: 'create', value: 'ROLE_DATABASE_SERVER_CREATE'},
            {type: 'update', value: 'ROLE_DATABASE_SERVER_UPDATE'},
            {type: 'delete', value: 'ROLE_DATABASE_SERVER_DELETE'},
          ],
        },
        {
          name: 'Databases',
          roles: [
            {type: 'get', value: 'ROLE_DATABASE_GET'},
            {type: 'create', value: 'ROLE_DATABASE_CREATE'},
            {type: 'update', value: 'ROLE_DATABASE_UPDATE'},
            {type: 'delete', value: 'ROLE_DATABASE_DELETE'},
          ],
        },
        {
          name: 'Database toegang',
          roles: [
            {type: 'create', value: 'ROLE_DATABASE_ACCESS_GRANT'},
            {type: 'update', value: 'ROLE_DATABASE_ACCESS_EXTEND'},
            {type: 'delete', value: 'ROLE_DATABASE_ACCESS_REVOKE'},
          ]
        }
      ],
    },
    {
      name: 'Message queue',
      roleCollections: [
        {
          name: 'Servers',
          roles: [
            {type: 'get', value: 'ROLE_MESSAGE_QUEUE_SERVER_GET'},
            {type: 'create', value: 'ROLE_MESSAGE_QUEUE_SERVER_CREATE'},
            {type: 'update', value: 'ROLE_MESSAGE_QUEUE_SERVER_UPDATE'},
            {type: 'delete', value: 'ROLE_MESSAGE_QUEUE_SERVER_DELETE'},
          ],
        },
        {
          name: 'Gebruikers',
          roles: [
            {type: 'get', value: 'ROLE_MESSAGE_QUEUE_USER_GET'},
            {type: 'create', value: 'ROLE_MESSAGE_QUEUE_USER_CREATE'},
            {type: 'update', value: 'ROLE_MESSAGE_QUEUE_USER_UPDATE'},
            {type: 'delete', value: 'ROLE_MESSAGE_QUEUE_USER_DELETE'},
          ],
        },
      ],
    },
    {
      name: 'Elastic',
      roleCollections: [
        {
          name: 'Deployments',
          roles: [
            {type: 'get', value: 'ROLE_ELASTIC_DEPLOYMENT_GET'},
            {type: 'create', value: 'ROLE_ELASTIC_DEPLOYMENT_CREATE'},
            {type: 'update', value: 'ROLE_ELASTIC_DEPLOYMENT_UPDATE'},
            {type: 'delete', value: 'ROLE_ELASTIC_DEPLOYMENT_DELETE'},
          ],
        },
        {
          name: 'Gebruikers',
          roles: [
            {type: 'get', value: 'ROLE_ELASTIC_USER_GET'},
            {type: 'create', value: 'ROLE_ELASTIC_USER_CREATE'},
            {type: 'update', value: 'ROLE_ELASTIC_USER_UPDATE'},
            {type: 'delete', value: 'ROLE_ELASTIC_USER_DELETE'},
          ],
        },
      ],
    },
    {
      name: 'Object storage',
      roleCollections: [
        {
          name: 'Accounts',
          roles: [
            {type: 'get', value: 'ROLE_OBJECT_STORAGE_ACCOUNT_GET'},
            {type: 'create', value: 'ROLE_OBJECT_STORAGE_ACCOUNT_CREATE'},
            {type: 'update', value: 'ROLE_OBJECT_STORAGE_ACCOUNT_UPDATE'},
            {type: 'delete', value: 'ROLE_OBJECT_STORAGE_ACCOUNT_DELETE'},
          ],
        },
        {
          name: 'Buckets',
          roles: [
            {type: 'get', value: 'ROLE_OBJECT_STORAGE_BUCKET_GET'},
            {type: 'create', value: 'ROLE_OBJECT_STORAGE_BUCKET_CREATE'},
            {type: 'update', value: 'ROLE_OBJECT_STORAGE_BUCKET_UPDATE'},
            {type: 'delete', value: 'ROLE_OBJECT_STORAGE_BUCKET_DELETE'},
          ],
        },
      ],
    },
    {
      name: 'Webservices',
      roleCollections: [
        {
          name: 'Gebruikers',
          roles: [
            {type: 'get', value: 'ROLE_WEBSERVICES_USER_GET'},
            {type: 'create', value: 'ROLE_WEBSERVICES_USER_CREATE'},
            {type: 'update', value: 'ROLE_WEBSERVICES_USER_UPDATE'},
            {type: 'delete', value: 'ROLE_WEBSERVICES_USER_DELETE'},
          ],
        },
      ],
    },
    {
      name: 'Cloudimage',
      roleCollections: [
        {
          name: 'Tokens',
          roles: [
            {type: 'get', value: 'ROLE_CLOUDIMAGE_TOKEN_GET'},
            {type: 'create', value: 'ROLE_CLOUDIMAGE_TOKEN_CREATE'},
            {type: 'update', value: 'ROLE_CLOUDIMAGE_TOKEN_UPDATE'},
            {type: 'delete', value: 'ROLE_CLOUDIMAGE_TOKEN_DELETE'},
          ],
        },
      ],
    },
    {
      name: 'SSL',
      roleCollections: [
        {
          name: 'Certificaten',
          roles: [
            {type: 'get', value: 'ROLE_SSL_CERTIFICATE_GET'},
            {type: 'create', value: 'ROLE_SSL_CERTIFICATE_CREATE'},
            {type: 'update', value: 'ROLE_SSL_CERTIFICATE_UPDATE'},
            {type: 'delete', value: 'ROLE_SSL_CERTIFICATE_DELETE'},
          ],
        },
      ],
    }
  ];

  const schema = Yup.object().shape({
    name: Yup.string().required('Dit veld mag niet leeg zijn'),
  });

  const onSubmit = (role: Role, actions: FormikHelpers<Role>) => {
    if (role.id === 0) {
      client
        .post('role', {
          ...role,
          roles: selectedRoles,
        })
        .then(() => toast.success('Rol aangemaakt'))
        .finally(() => navigate('/role'));
    } else {
      client
        .put(`role/${role.id}`, {
          ...role,
          roles: selectedRoles,
        })
        .then(() => toast.success('Rol gewijzigd'))
        .finally(() => actions.setSubmitting(false));
    }
  };

  const renderIcon = (roleType: string) => {
    switch (roleType) {
      case 'get':
        return (
          <Tooltip title={String(t('role:role_types.show'))} placement="top">
            <VisibilityOutlined />
          </Tooltip>
        );
      case 'create':
        return (
          <Tooltip title={String(t('role:role_types.create'))} placement="top">
            <Add />
          </Tooltip>
        );
      case 'update':
        return (
          <Tooltip title={String(t('role:role_types.update'))} placement="top">
            <EditOutlined />
          </Tooltip>
        );
      case 'delete':
        return (
          <Tooltip title={String(t('role:role_types.delete'))} placement="top">
            <Delete />
          </Tooltip>
        );
      case 'dns_own':
        return (
          <Tooltip title={String(t('role:role_types.own_dns'))} placement="top">
            <Person />
          </Tooltip>
        );
      case 'dns_all':
        return (
          <Tooltip title={String(t('role:role_types.all_dns'))} placement="top">
            <People />
          </Tooltip>
        );
      default:
        return <></>;
    }
  };

  const breadcrumbs: Array<BreadcrumbItem> = [
    {title: 'Rollen', url: '/role'},
    {title: 'Rol', url: null},
  ];

  return (
    <>
      <Box sx={{mb: 5}}>
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>{role.id === 0 ? 'Nieuwe rol' : 'Rol wijzigen'}</Typography>
        <Breadcrumb items={breadcrumbs} />
      </Box>
      <Paper sx={{p: 6}}>
        <Formik initialValues={role} validationSchema={schema} onSubmit={onSubmit} enableReinitialize={true}>
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              isSubmitting,
            }) => (
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" fontWeight="bold">Gegevens</Typography>
              <Box>
                <TextField
                  sx={{mb: 5}}
                  name="name"
                  label="Naam"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={errors.name && touched.name && errors.name}
                  fullWidth
                />
                {RoleHierarchy.map((category) => (
                  <>
                    <Typography variant="subtitle1" fontWeight="bold">{category.name}</Typography>

                    {category.roleCollections.map((roleCollection, roleCollectionIndex) => {
                      const allRolesActive = roleCollection.roles.every((role) => selectedRoles.includes(role.value));

                      return (
                        <List dense={true} key={roleCollectionIndex} sx={{py: 0}}>
                          <ListItem sx={{
                            pl: 0,
                            py: 2,
                            borderBottom: '1px solid #e0e0e0',
                          }} secondaryAction={
                            <>
                              <StyledToggleButtonGroup
                                value={selectedRoles}
                                exclusive
                                onChange={(event, value) => {
                                  (!selectedRoles.includes(value)) ? setSelectedRoles([...selectedRoles, value]) : setSelectedRoles(selectedRoles.filter((role) => role !== value));
                                }}
                              >
                                {roleCollection.roles.map((role, roleIndex) => (
                                  <ToggleButton value={role.value} aria-label={role.value} key={roleIndex}>
                                    {renderIcon(role.type)}
                                  </ToggleButton>
                                ))}
                              </StyledToggleButtonGroup>
                              <Switch
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setSelectedRoles([...selectedRoles, ...roleCollection.roles.map((role) => role.value).filter((role) => !selectedRoles.includes(role))]);
                                  } else {
                                    setSelectedRoles(selectedRoles.filter((role) => !roleCollection.roles.map((role) => role.value).includes(role)));
                                  }
                                }}
                                checked={allRolesActive}
                              />
                            </>
                          }
                          >
                            <ListItemText primary={roleCollection.name}/>
                          </ListItem>
                        </List>
                      );
                    })}
                    <br />
                  </>
                ))}
              </Box>
              <Box>
                <LoadingButton type="submit" color="primary" variant="contained" disabled={!isValid} loading={isSubmitting}>
                  {values.id === 0 ? 'Aanmaken' : 'Wijzigen'}
                </LoadingButton>
                <Button onClick={() => navigate('/role')} color="secondary" disabled={isSubmitting} sx={{ml: 2}}>Annuleren</Button>
                <Box style={{flex: '1 0 0'}} />
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </>
  )
}

export default Form;
